import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { GLOBAL_BLACKLIST } from 'src/app/shared/model/constants';

export class CustomValidator {
  GLOBAL_BLACKLIST = [
    '00000000',
    '11111111',
    '22222222',
    '33333333',
    '44444444',
    '55555555',
    '66666666',
    '77777777',
    '88888888',
    '99999999',
    '12345678',
    '123123123'
  ];

  static getFormattedDate(date): string {
    alert(date)
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return day + '-' + month + '-' + year;
  }

  static validateDate(fc: UntypedFormControl): { validateDate: boolean } | { validateDate: { valid: boolean } } | null {
    if (fc.value) {
      alert(fc.value)
      const regEx = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      if (fc.value.match(regEx) == null) {
        return {
          validateDate: true,
        };
      }
      if (fc.value.split('/')[2] < 1900) {
        return {
          validateDate: true,
        };
      }
      const today = new Date();
      const inputDate = new Date(fc.value);
      const diff = today.getTime() - inputDate.getTime();
      const ageDateTime = new Date(diff);
      const age = Math.abs(ageDateTime.getUTCFullYear() - 1970);
      if (age < 18 || age > 150) {
        return { validateDate: { valid: false } };
      }
    }
    return null;
  }

  //validate no of emails
  static validateEmailCount(fc: UntypedFormControl): { validateEmailCount: boolean } | null {
    if (fc.value != '') {
      const allEmails = fc.value.split(',');
      if (allEmails.length > 5) {
        return {
          validateEmailCount: true,
        };
      }
    }
    return null;
  }

  //validate multiple emails
  static validateMultiEmailField(fc: UntypedFormControl): { validateMultiEmailField: boolean } | null {
    if (fc.value != '') {
      const allEmails = fc.value.split(',');
      const pattern = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
      for (let i = 0; i < allEmails.length; i++) {
        if (allEmails[i].match(pattern) == null) {
          return {
            validateMultiEmailField: true,
          };
        }
      }
    }
    return null;
  }
  static validateMCCode(fc: UntypedFormControl): { validateMCCode: boolean } | null {
    if (fc.value) {
      return {
        validateMCCode: true,
      };
    }
    return null;
  }

  static validateFinanceCenter(fc: UntypedFormControl): { validateFinanceCenter: boolean } | null {
    if (fc.value) {
      return {
        validateFinanceCenter: true,
      };
    }
    return null;
  }

  static validateSsn(fc: UntypedFormControl): { validateSsn: boolean } | null {
    if (fc.value) {
      // let regEx = /(^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$)|(^(9\d{2})([ \-]?)(5[0-9]|6[0-5]|8[3-8]|9[0-2]|9[4-9])([ \-]?)(\d{4})$)/;
      const regEx = /(^(?!000|666)[0-8][[0-9]{2}-(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$)/;
      if (fc.value.match(regEx) == null) {
        return {
          validateSsn: true,
        };
      } else {
        if (CustomValidator.validatePattern(fc.value)) {
          return {
            validateSsn: true,
          };
        }
      }
    }
    return null;
  }

  
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  static validatePattern(fcValue: any): { validatePattern: boolean } | null {
    const ssnAndFederalTaxRegExRange = /(^[a]{8}[0-9]{1}$)|(^[1]{8}[0-9]{1}$)|(^[2]{8}[0-9]{1}$)|(^[3]{8}[0-9]{1}$)|(^[4]{8}[0-9]{1}$)|(^[5]{8}[0-9]{1}$)|(^[6]{8}[0-9]{1}$)|(^[7]{8}[0-9]{1}$)|(^[8]{8}[0-9]{1}$)|(^[9]{8}[0-9]{1}$)|(^(12345678)[0-9]{1}$)/;
    const groupPatternRegEx = /\b(\d+)\1+\b/;
    const groupPattern = RegExp(groupPatternRegEx);
    const ssnFederalTaxRegex = RegExp(ssnAndFederalTaxRegExRange);
    if (fcValue != '') {
      const formattedSSN = fcValue.replace(/-/g, '');
      if (groupPattern.test(formattedSSN)) {
        return {
          validatePattern: true,
        };
      } else if (ssnFederalTaxRegex.test(formattedSSN)) {
        return {
          validatePattern: true,
        };
      }
    }
    return null;
  }

  static validateEstablishedDate(fc: UntypedFormControl): { validateEstablishedDate: boolean } | null {
    if (fc.value) {
      const regEx = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      if (regEx.test(fc.value) == null) {
        return {
          validateEstablishedDate: true,
        };
      } else {
        if (fc.value.year < 1000) {
          return {
            validateEstablishedDate: true,
          };
        }
        const today = new Date();
        const formatteddate = CustomValidator.getFormattedDate(today);
        const currentMonth = formatteddate.split('/')[0];
        const currentDay = formatteddate.split('/')[1];
        const currentYear = formatteddate.split('/')[2];
        const formattedBirthDate = CustomValidator.getFormattedDate(fc.value);
        const enteredMonth = formattedBirthDate.split('/')[0];
        const enteredDay = formattedBirthDate.split('/')[1];
        const entertedYear = formattedBirthDate.split('/')[2];
        if (entertedYear > currentYear) {
          return {
            validateEstablishedDate: true,
          };
        } else if (entertedYear == currentYear) {
          if (enteredMonth > currentMonth) {
            return {
              validateEstablishedDate: true,
            };
          } else if (enteredMonth == currentMonth) {
            if (enteredDay > currentDay) {
              return {
                validateEstablishedDate: true,
              };
            }
          }
        }
      }
    }
    return null;
  }



  static validateDateFormatNew(fc: UntypedFormControl): { validateDateFormatNew: boolean } | null {

    var fromDate:any;
    var toDate:any;
    if(fc.value!=null || fc.value!=undefined){
      fromDate = fc.value.start;
      toDate = fc.value.end;
    }
    
    var startDate: any;
    var endDate: any;
    
    if (fromDate != undefined && toDate != undefined) {
      startDate = moment(fromDate).format("YYYY-MM-DD");
      endDate = moment(toDate).format("YYYY-MM-DD");
      fc.value.start = startDate;
      fc.value.end = endDate;
      if (startDate && endDate) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        // if ((fc.value.year+'-'+((fc.value.month + '').length == 1 ? '0' + fc.value.month : fc.value.month)+'-'+((fc.value.day + '').length == 1 ? '0' + fc.value.day : fc.value.day)).match(regEx) ==null) {
        if ((startDate.match(regEx) == null) && (endDate.match(regEx) == null)) {
          return {
            validateDateFormatNew: true,
          };
        }
      }
    }

    return null;

  }

 static validateNumberFormat(fc: UntypedFormControl): { validateNumberFormat: boolean } | null {
   var notificId=fc.value;  
   if(notificId){    
    if(isNaN(Number(notificId)) || notificId.indexOf(' ') >= 0 || notificId.indexOf('+') >= 0|| notificId.indexOf('-') >= 0){
      return {
        validateNumberFormat: true,
      };
     }
   }
   return null;
 }
  static specialCharValidator(control: UntypedFormControl): { [key: string]: boolean } {
  const nameRegexp: RegExp = /^[a-zA-Z0-9]+$/;
  if (!(control.value && nameRegexp.test(control.value))) {
     return { invalidName: true };
  }
}

 static validateForm(fc: UntypedFormControl): { validateForm: boolean } | null {
     return {      
      validateForm: true,
     };
   
}
  static dateLessThan(fromDate: any, toDate: any) {
    const from=fromDate.year+'-'+((fromDate.month + '').length == 1 ? '0' + fromDate.month : fromDate.month)+'-'+((fromDate.day + '').length == 1 ? '0' + fromDate.day : fromDate.day)
    const to=toDate.year+'-'+((toDate.month + '').length == 1 ? '0' + toDate.month : toDate.month)+'-'+((toDate.day + '').length == 1 ? '0' + toDate.day : toDate.day)

    return (group: UntypedFormGroup): {[key: string]: any} => {
     let f = group.controls[from];
     let t = group.controls[to];
     if (f.value > t.value) {
       return {
         dates: "Date from should be less than Date to"
       };
     }
     return {};
    }
  }

  static dateLessThanNew(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        const date1 = c.get(dateField1).value;
        const date2 = c.get(dateField2).value;
        if ((date1 !== null && date2 !== null) && date1 > date2) {
            return validatorField;
        }
        return null;
    };
}

  static validateDateFormat(fc: UntypedFormControl): { validateDateFormat: boolean } | null {
    if (fc.value) {
      const regEx = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      if (fc.value.match(regEx)) {
        return {
          validateDateFormat: true,
        };
      }
    }
    return null;
  }

  static validateZipcode(fc: UntypedFormControl): { validateZipcode: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      if (fc.value == '00000' || fc.value == '00000-0000') {
        return {
          validateZipcode: true,
        };
      }
      const regEx = /(^\d{5}$)/;
      if (fc.value.match(regEx) == null) {
        return {
          validateZipcode: true,
        };
      }
    }
    return null;
  }

  static validateFederalTax(fc: UntypedFormControl): { validateFederalTax: boolean } | null {
    if (fc.value != '') {
      const regEx = /^(0[1-6]|1[0-6]|2[0-7]|3[0-9]|4[0-8]|5[0-9]|6[0-8]|7[1-7]|8[0-8]|9[0-58-9])\d{1}-\d{6}$/;
      if (fc.value.match(regEx) == null) {
        return {
          validateFederalTax: true,
        };
      } else {
        if (CustomValidator.validatePattern(fc.value)) {
          return {
            validateFederalTax: true,
          };
        }
      }
    }
    return null;
  }

  static validateFederalAndSSN(fc: UntypedFormControl): { validateFederalAndSSN: boolean } | null {
    if (fc.value != '') {
      const regEx = /^(0[1-6]|1[0-6]|2[0-7]|3[0-9]|4[0-8]|5[0-9]|6[0-8]|7[1-7]|8[0-8]|9[0-58-9])\d{1}-\d{6}$/;
      const regEx2 = /(^(?!000|666)[0-8][[0-9]{2}-(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$)/;
      if (fc.value.match(regEx) == null && fc.value.match(regEx2) == null) {
        return {
          validateFederalAndSSN: true,
        };
      } else {
        if (CustomValidator.validatePattern(fc.value)) {
          return {
            validateFederalAndSSN: true,
          };
        }
      }
    }
    return null;
  }

  static checkRoutingNumber(control: UntypedFormControl): { checkRoutingNumber: boolean } | null {
    const routingNumber = control.value;
    if (routingNumber) {
      if (routingNumber.match(/^[0-9]{9}$/)) {
        let sum = 0;
        for (let i = 0; i < routingNumber.length; i += 3) {
          sum += parseInt(routingNumber.charAt(i), 10) * 3 + parseInt(routingNumber.charAt(i + 1), 10) * 7 + parseInt(routingNumber.charAt(i + 2), 10);
        }
        if (!(sum != 0 && sum % 10 == 0)) {
          return { checkRoutingNumber: true };
        }
      }
    }

    return null;
  }

  static compareRouting(fc: UntypedFormControl): { compareRouting: boolean } | null {
    if (fc.parent !== undefined && fc.value != '') {
      const currentModel = fc.parent.value;
      if (currentModel.bankRoutingNumber != fc.value || fc.parent.value.confirmBankRoutingNumber == '') {
        return {
          compareRouting: true,
        };
      }
    }
    return null;
  }

  static compareAccount(fc: UntypedFormControl): { compareAccount: boolean } | null {
    if (fc.parent !== undefined && fc.value != '') {
      const currentModel = fc.parent.value;
      if (currentModel.bankAccountNumber != fc.value) {
        return {
          compareAccount: true,
        };
      }
    }
    return null;
  }

  static validateLicense(fc: UntypedFormControl): { validateLicense: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      const regEx = /^[-a-zA-Z0-9 #()./\\]+$/;
      if (fc.value.match(regEx) == null) {
        return {
          validateLicense: true,
        };
      }
    }
    return null;
  }

  static validateOwnership(fc: UntypedFormControl): { validateOwnership: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      let ownerShipSum = 0;
      const ownerPercentage: number = fc.value;
      if (ownerPercentage < 25 || ownerPercentage > 100) {
        return {
          validateOwnership: true,
        };
      } else {
        if (fc.parent.parent.controls) {
          Object.keys(fc.parent.parent.controls).forEach((key) => {
            const abstractControl = fc.parent.parent.controls[key];
            if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
              ownerShipSum += Number(abstractControl.controls['nOwnership'].value);
            }
          });
          if (ownerShipSum > 100) {
            return {
              validateOwnership: true,
            };
          }
        }
      }
    }
    return null;
  }

  static validateOwnershipControl(fc: UntypedFormControl): { validateOwnershipControl: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      let ownerShipSum = 0;
      const ownerPercentage: number = fc.value;
      if (ownerPercentage < 0 || ownerPercentage > 100 || ownerPercentage.toString().charAt(0) == '-') {
        return {
          validateOwnershipControl: true,
        };
      } else {
        if (fc.parent.parent.controls) {
          Object.keys(fc.parent.parent.controls).forEach((key) => {
            const abstractControl = fc.parent.parent.controls[key];
            if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
              ownerShipSum += Number(abstractControl.controls['nOwnership'].value);
            }
          });
          if (ownerShipSum > 100) {
            return {
              validateOwnershipControl: true,
            };
          }
        }
      }
    }
    return null;
  }

  static validateAndUpdateMCCDescription(mCCvalue: string): string {
    if (mCCvalue) {
      const inputPattern = RegExp(/\((MCC: \d{4}\))/);
      if (inputPattern.test(mCCvalue)) {
        return mCCvalue.slice(0, mCCvalue.lastIndexOf('(')).trim();
      }
    }
    return mCCvalue;
  }

  static formatDate(dateString: string): string {
    if (dateString) {
      const dateArray = dateString.split('-');
      return dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
    }
  }

  static validateCardNotPresent(fc: UntypedFormControl): { validateCardNotPresent: boolean } | null {
    if (fc.value) {
      if (fc.value > 100) {
        return {
          validateCardNotPresent: true,
        };
      }
    }
    return null;
  }

  static validateCurrency(fc: UntypedFormControl): { validateCurrency: boolean } | null {
    if (fc.value) {
      const currency = fc.value.replace(/,/g, '');
      if (currency > 999999999) {
        return {
          validateCurrency: true,
        };
      }
    }
    return null;
  }

  static validateCurrencyBundledPage(fc: UntypedFormControl): { validateCurrencyBundledPage: boolean } | null {
    if (fc.value) {
      const currency = fc.value.replace(/,/g, '');
      if (currency > 999999999.99) {
        return {
          validateCurrencyBundledPage: true,
        };
      }
    }
    return null;
  }

  static validateCurrencyMinimum(fc: UntypedFormControl): { validateCurrencyMinimum: boolean } | null {
    if (fc.value) {
      const currency = fc.value.replace(/,/g, '');
      if (currency == 0.0 || currency == 0) {
        return {
          validateCurrencyMinimum: true,
        };
      }
    }
    return null;
  }

  static validateCopyPaste(fc: UntypedFormControl): { validateCopyPaste: boolean } | null {
    if (fc.value) {
      const text = fc.value.replace(/,/g, '');
      let i = text.length;
      let numberCount = 0;
      while (i--) {
        if (text.charCodeAt(i) >= 48 && text.charCodeAt(i) <= 57) {
          numberCount += 1;
        }
      }
      if (numberCount != text.length) {
        return {
          validateCopyPaste: true,
        };
      }
    }
    return null;
  }

  static validateZeros(fc: UntypedFormControl): { validateZeros: boolean } | null {
    if (fc.value) {
      const ebtFcs = fc.value;
      if (Number(ebtFcs) == 0 && ebtFcs.length > 9) {
        return {
          validateZeros: true,
        };
      }
    }
    return null;
  }

  static validateZerosFns(fc: UntypedFormControl): { validateZerosFns: boolean } | null {
    if (fc.value) {
      const ebtFcs = fc.value;
      if (Number(ebtFcs) == 0 && ebtFcs.length > 6) {
        return {
          validateZerosFns: true,
        };
      }
    }
    return null;
  }

  static validatePhoneNumber(fc: UntypedFormControl): { validatePhoneNumber: boolean } | null {

    if (fc.value && fc.value != '') {
      const phoneNumber = fc.value.replace(/[^+0-9]/g, '');
      if (phoneNumber.length == 10) {
        const phoneArray = phoneNumber.split('');
        let phNumber = true;
        for (let i = 0; i < phoneArray.length - 1; i++) {
          if (phoneArray[i] != phoneArray[i + 1]) {
            phNumber = false;
          }
        }
        if (phNumber) {
          return {
            validatePhoneNumber: true,
          };
        }
      }
      const regEx = '^[+0-9]{10,15}$';
      if (fc.value.match(regEx) == null) {
        return {
          validatePhoneNumber: true,
        };
      }
    }
    return null;
  }

  static validateZeroValue(fc: UntypedFormControl): { validateZeroValue: boolean } | null {
    if (fc.value) {
      const varValue = fc.value;
      if (Number(varValue) == 0 || varValue == 0.0) {
        return {
          validateZeroValue: true,
        };
      }
    }
    return null;
  }

  static validateIQStdFee(fc: UntypedFormControl): { validateIQStdFee: boolean } | null {
    if (fc.value) {
      const varValue = fc.value;
      if (Number(varValue) != 0 && Number(varValue) != 5) {
        return {
          validateIQStdFee: true,
        };
      }
    }
    return null;
  }

  //Issue date format
  static validateIssueDateFormat(fc: UntypedFormControl): { validateIssueDateFormat: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      const regEx = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      let birthDate;
      if (fc.value.match(regEx) == null) {
        return {
          validateIssueDateFormat: true,
        };
      } else {
        if (!CustomValidator.validateEstablishedDate(fc)) {
          let data;
          if (fc.parent.controls) {
            data = fc.parent.controls;
          } else {
            data = fc.parent.parent.controls;
          }
          Object.keys(data).forEach((key) => {
            const abstractControl = data[key];
            if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
              if (abstractControl.parent.controls['nDateOfBirth'] || abstractControl.parent.controls['dateOfBirth']) {
                if (abstractControl.parent.controls['nDateOfBirth']) {
                  birthDate = abstractControl.parent.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.parent.controls['dateOfBirth'].value;
                }
              } else {
                if (abstractControl.controls['nDateOfBirth']) {
                  birthDate = abstractControl.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.controls['dateOfBirth'].value;
                }
              }
            } else if (abstractControl instanceof UntypedFormControl) {
              if (abstractControl.parent.controls['nDateOfBirth'] || abstractControl.parent.controls['dateOfBirth']) {
                if (abstractControl.parent.controls['nDateOfBirth']) {
                  birthDate = abstractControl.parent.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.parent.controls['dateOfBirth'].value;
                }
              }
            }
          });
          if (!birthDate || birthDate == '') {
            birthDate = '01/01/1900';
          }
          const formattedBirthDate = CustomValidator.getFormattedDate(new Date(birthDate));
          const birthMonth = formattedBirthDate.split('/')[0];
          const actualDay = formattedBirthDate.split('/')[1];
          const birthYear = formattedBirthDate.split('/')[2];

          const formattedIssueDate = CustomValidator.getFormattedDate(new Date(fc.value));
          const issueMonth = formattedIssueDate.split('/')[0];
          const issueDay = formattedIssueDate.split('/')[1];
          const issueYear = formattedIssueDate.split('/')[2];

          if (issueYear < birthYear) {
            return {
              validateIssueDateFormat: true,
            };
          } else if (issueYear == birthYear) {
            if (issueMonth < birthMonth) {
              return {
                validateIssueDateFormat: true,
              };
            } else if (issueMonth == birthMonth) {
              if (issueDay < actualDay) {
                return {
                  validateIssueDateFormat: true,
                };
              }
            }
          }
        } else {
          return {
            validateIssueDateFormat: true,
          };
        }
      }
    }
    return null;
  }

  //Expiration date format
  static validateExpirationDateFormat(fc: UntypedFormControl): { validateExpirationDateFormat: boolean } | null {
    if (fc.value && fc.parent !== undefined) {
      const regEx = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      let birthDate;
      if (fc.value.match(regEx) == null) {
        return {
          validateExpirationDateFormat: true,
        };
      } else {
        if (CustomValidator.validateEstablishedDate(fc)) {
          let data;
          if (fc.parent.controls) {
            data = fc.parent.controls;
          } else {
            data = fc.parent.parent.controls;
          }
          Object.keys(data).forEach((key) => {
            const abstractControl = data[key];
            if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
              if (abstractControl.parent.controls['nDateOfBirth'] || abstractControl.parent.controls['dateOfBirth']) {
                if (abstractControl.parent.controls['nDateOfBirth']) {
                  birthDate = abstractControl.parent.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.parent.controls['dateOfBirth'].value;
                }
              } else {
                if (abstractControl.controls['nDateOfBirth']) {
                  birthDate = abstractControl.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.controls['dateOfBirth'].value;
                }
              }
            } else if (abstractControl instanceof UntypedFormControl) {
              if (abstractControl.parent.controls['nDateOfBirth'] || abstractControl.parent.controls['dateOfBirth']) {
                if (abstractControl.parent.controls['nDateOfBirth']) {
                  birthDate = abstractControl.parent.controls['nDateOfBirth'].value;
                } else {
                  birthDate = abstractControl.parent.controls['dateOfBirth'].value;
                }
              }
            }
          });
          if (!birthDate || birthDate == '') {
            birthDate = '01/01/1900';
          }
          const formattedBirthDate = CustomValidator.getFormattedDate(new Date(birthDate));
          const issueMonth = formattedBirthDate.split('/')[0];
          const issueDay = formattedBirthDate.split('/')[1];
          const issueYear = formattedBirthDate.split('/')[2];

          const formattedIssueDate = CustomValidator.getFormattedDate(new Date(fc.value));
          const expMonth = formattedIssueDate.split('/')[0];
          const expDay = formattedIssueDate.split('/')[1];
          const expYear = formattedIssueDate.split('/')[2];
          if (expYear < issueYear) {
            return {
              validateExpirationDateFormat: true,
            };
          } else if (expYear == issueYear) {
            if (issueMonth > expMonth) {
              return {
                validateExpirationDateFormat: true,
              };
            } else if (issueMonth == expMonth) {
              if (issueDay >= expDay) {
                return {
                  validateExpirationDateFormat: true,
                };
              }
            }
          }
        } else {
          return {
            validateExpirationDateFormat: true,
          };
        }
      }
    }
    return null;
  }

  static isValidEin(value: string): boolean {
    const einRegex = /^\d{2}[- ]{0,1}\d{7}$/;
    const prefixes = [];
    const CAMPUS = {
      andover: ['10', '12'],
      atlanta: ['60', '67'],
      austin: ['50', '53'],
      brookhaven: ['01', '02', '03', '04', '05', '06', '11', '13', '14', '16', '21', '22', '23', '25', '34', '51', '52', '54', '55', '56', '57', '58', '59', '65'],
      cincinnati: ['30', '32', '35', '36', '37', '38', '61'],
      fresno: ['15', '24'],
      internet: ['20', '26', '27', '45', '46', '47'],
      kansas: ['40', '44'],
      memphis: ['94', '95'],
      ogden: ['80', '90'],
      philadelphia: ['33', '39', '41', '42', '43', '46', '48', '62', '63', '64', '66', '68', '71', '72', '73', '74', '75', '76', '77', '81', '82', '83', '84', '85', '86', '87', '88', '91', '92', '93', '98', '99'],
      sba: ['31']
    };
    for (const location in CAMPUS) {
      prefixes.push(...CAMPUS[location]);
    }
    if (!einRegex.test(value)) {
      return false;
    }
    return prefixes.indexOf(value.substr(0, 2)) > -1;
  }

  static isValidItin(value: string): boolean {
    const itinRegex = /^(9\d{2})((5[0-9]{1})|(6[0-5]{1})|(8[3-8]{1})|(9[0-2]{1})|(9[4-9]{1}))(\d{4})$/;
    return itinRegex.test(value.replace(/-/g, ''));
  }

  static isValidSsn(value: string): boolean {
    const SSN_BLACKLIST = ['078051120', '219099999', '457555462'];
    const ssnRegex = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
    if (!ssnRegex.test(value)) {
      return false;      
    }
    return SSN_BLACKLIST.indexOf(value.replace(/\D/g, '')) > -1;
  }

  static isValidTin(fc: UntypedFormControl): { validateTaxId: boolean } | null {
    if (fc.value != '') {
      for (const blacklisted of GLOBAL_BLACKLIST) {
        if (fc.value.replace(/\D/g, '').startsWith(blacklisted)) {
          return {
            validateTaxId: true
          };
        }
      }
      
      if (CustomValidator.isValidItin(fc.value) || CustomValidator.isValidEin(fc.value)) {              
        return null;
      } else {        
        return {
          validateTaxId: true
        };
      }
    }
  }


  static specialCharacter(control: UntypedFormControl): {
    [key: string]: boolean
  } {
    const regexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const regexpWhitespace: RegExp = /\s/;
    if ((control.value && regexp.test(control.value)) || regexpWhitespace.test(control.value)) {
       return {
          invalidField: true
       };
    }
 }

 static subscriberCodeValid(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexSubscriberCode: RegExp = /^(?:[a-zA-Z0-9_~-]+)?$/;
  const regexStartsWhitespace: RegExp = /^\s/;  
   if (control.value != '' && control.value != null) {
     if (regexStartsWhitespace.test(control.value)) {
       return {
         emptyString: true
       };
     } else if (!regexSubscriberCode.test(control.value)) {
       return {
         invalidSubscriberCode: true
       };
     }
     if (control.value.length > 25) {
       return {
         maxlength: true
       }
     }
   }
}

 static subscriberNameValid(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexSubscriberName: RegExp = /^(?:[a-zA-Z0-9\s-'&$*.,\[\]{}<>]+)?$/;
  const regexStartsWhitespace: RegExp = /^\s/;
  if (control.value != '' && control.value != null) {
    if (regexStartsWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } else if (!regexSubscriberName.test(control.value)) {
      return {
        invalidSubscriberName: true
      };
    }
    if (control.value.length > 50) {
      return {
        maxlength: true
      }
    }
  }
}

 static checkMaxLengthAndEmpty(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexpWhitespace: RegExp = /^\s/;
  if (regexpWhitespace.test(control.value)) {
     return {
      emptyString: true
     };
  }if(control.value.length>50){
    return{
      maxlength: true
    }
  }
}

static validPersonName(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexStartsWhitespace: RegExp = /^\s/;
  const regexPerson: RegExp = /^(?:[a-zA-Z0-9\s-'_]+)?$/;
  if (control.value != '' && control.value != null) {

    if (regexStartsWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } else if (!regexPerson.test(control.value)) {
      return {
        invalidPersonName: true
      };
    }
    if (control.value.length > 100) {
      return {
        maxlength: true
      }
    }
  }
}

static validDescription(control: UntypedFormControl): {
  [key: string]: boolean
} { 
  const regexStartsWhitespace: RegExp = /^\s/;
  const regexPerson: RegExp = /^(?:[a-zA-Z0-9\s-'&,.]+)?$/;
  
  if (control.value != '' && control.value != null) {
    if (regexStartsWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } else if (!regexPerson.test(control.value)) {
      return {
        invalidDescription: true
      };
    }
    if (control.value.length > 160) {
      return {
        maxlength: true
      }
    }
  }
}

static validPhoneNumber(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexStartsWhitespace: RegExp = /^\s/;
  const regexPhoneNumber: RegExp = /^\+?[\d]{6,16}$/;

  if (control.value != '' && control.value != null) {
    if (regexStartsWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } else if (!regexPhoneNumber.test(control.value)) {
      return {
        invalidPhoneNumber: true
      };
    }
  }
}

static checkMaxLengthAndEmptySpace(val: number): ValidatorFn {
 
  return (control: AbstractControl) => {
 
    const regexpWhitespace: RegExp = /^\s/;
    if (regexpWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } 
    if (control.value.length > val) {
      return {
        maxlength: true
      }
    }
    
  }
 
}

static validateUrl(): ValidatorFn {
 
  return (control: AbstractControl) => {
 
    const regexpWhitespace: RegExp = /^\s/;
    const urlRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (urlRegex.test(control.value)) {
      return {
        invalidUrl: true
      };
    } 
    
  }
 
}
 static validateEmailField(fc: UntypedFormControl): { validateEmailField: boolean } | null {
  if (fc.value != '' && fc.value != null) {
    const emailRegex: RegExp = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const pattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (fc.value.match(emailRegex) == null) {
        return {
          validateEmailField: true,
        };
      }
  }
  return null;
}

static validateSpecialCharacter(fc: UntypedFormControl): { specialCharInText: boolean } | null {
  if (fc.value != '' && fc.value != null) {
    const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const pattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (fc.value.match(nameRegexp) == null) {
        return {
          specialCharInText: true,
        };
      }
  }
  return null;
}

// Validate sign key name based on sign type selection
static checkSignKey(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const signKey = control.value ;
  let signType ;

  if (control.parent !== undefined && control.parent !== null) {
   let data;
    if (control.parent.controls) {
      data = control.parent.controls;
    } else {
      data = control.parent.parent.controls;
    }

    const abstractControl = data;
    signType = abstractControl['signType'].value;

    if (signType == undefined || signType === '') {
      return null ;
    }  

    if ((signType !== '' || signType !== undefined) && signKey === '') {
    //   abstractControl['signType'].setErrors({'emptyString': true});
    //   abstractControl['signKeyName'].setErrors({'emptyString': false});          
    //  return{
    //     emptyString: true
    //   }
    }else if ((signType !== '' || signType !== undefined) && signKey !== '') {
      abstractControl['signType'].setErrors(null);
    }
    
    if(signKey.length>25){
     return{
       maxlength: true
     }
    }
}

return null ;
}

static checkSignType(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const signType = control.value ;
  let signKey ;

  if (control.parent !== undefined && control.parent !== null) {
   let data;
    if (control.parent.controls) {
      data = control.parent.controls;
    } else {
      data = control.parent.parent.controls;
    }
    const abstractControl = data;
    signKey = abstractControl['signKeyName'].value;

    if (signType === '' || signType == undefined) {
      abstractControl['signKeyName'].setErrors(null);
    }
    // if ((signKey === '' || signKey == undefined)&&( signType !== '' && signType !== undefined)) {     
    //   return {
    //    emptyString: true
    //   };
    // }       
}
  return null ;
}

/**
 * US987755 - Ability to manage the subscribers - Inclusion of the Hierarchy Details in the Subscriber Creation Flow and Edit Flow
 * E5660037
 */

static checkHyLevelAndValue(control: UntypedFormControl) {
  const commonValue = control.value ;
  if(control.parent !== undefined && control.parent !== null) {
    let data, hierarchyLevel, hierarchyValue;
    if (control.parent.controls) {
      data = control.parent.controls;
    } else {
      data = control.parent.parent.controls;
    }
    const abstractControl = data;
    hierarchyLevel = abstractControl['hierarchyLevel'].value;
    hierarchyValue = abstractControl['hierarchyValue'].value;
    if(commonValue === 'RESTRICTED') {
      abstractControl['hierarchyLevel'].setErrors({required: true});
      abstractControl['hierarchyValue'].setErrors({required: true});
    } else {
      abstractControl['hierarchyLevel'].setErrors({required: false});
      abstractControl['hierarchyValue'].setErrors({required: false});
    }
  }
  return null;
}

static checkHyLevel(control: UntypedFormControl) {
  const commonValue = control.value ;
  if(control.parent !== undefined && control.parent !== null) {
    let data, hierarchyLevel;
    if (control.parent.controls) {
      data = control.parent.controls;
    } else {
      data = control.parent.parent.controls;
    }
    const abstractControl = data;
    if(commonValue != undefined) {
      if(commonValue.length == 0) {
        abstractControl['hierarchyLevel'].setErrors({required: true});
      } else {
        abstractControl['hierarchyLevel'].setErrors({required: false});
      }
    }
  }
  return null;
}

static checkHyValue(control: UntypedFormControl) {
  const commonValue = control.value ;
  if(control.parent !== undefined && control.parent !== null) {
    let data, hierarchyValue;
    if (control.parent.controls) {
      data = control.parent.controls;
    } else {
      data = control.parent.parent.controls;
    }
    const abstractControl = data;
    if(parseInt(commonValue.length) === 0) {
      abstractControl['hierarchyValue'].setErrors({required: true});
    } else {
      abstractControl['hierarchyValue'].setErrors({required: false});
    }
  }
  return null;
}

static nonWhitespaceValidator(control: UntypedFormControl) { /* To check whitespace from front & end of the string*/
  const regexpWhitespace: RegExp = /\s+$/gm;
  const regexpWhitespaceList: RegExp = /^\s/gm;
  if (regexpWhitespace.test(control.value) || regexpWhitespaceList.test(control.value)) {
    return {
     emptyString: true
    };
  }
}

static maxValues(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  if(control.value != null && control.value.length>10){
    return{
      maxValues: true
    }
  }
}

static validateV1Notification(control: UntypedFormControl) {

  control.setErrors(null);
  var notificId = control.value;  
  if(notificId){ 
   if(isNaN(Number(notificId)) || notificId.indexOf(' ') >= 0 || notificId.indexOf('+') >= 0|| notificId.indexOf('-') >= 0){
     return {
       validateNumberFormat: true,
     };
    }
  }
  if(control.value != null && control.value.length>10){
    return{
      maxValues: true
    }
  }

  return null;
}

static validateIntegrationType(baseValue:string):ValidatorFn{
  return (control:AbstractControl): ValidationErrors | null =>{
    const value = control.value;
    if((value && baseValue) && baseValue!=value && value =='PAYLOAD_INTEGER')
    return {invalidString: false}
  }
  return null;
}

static validateDescription(fc: UntypedFormControl): { invalidDescription: boolean } | null {
  if (fc.value != '' && fc.value != null) {  
    const nameRegexp: RegExp = /^[a-zA-Z0-9.,-?&' ]{1,2000}$/;
      if (fc.value.match(nameRegexp) == null) {
        return {
          invalidDescription: true,
        };
      }
  }
  return null;
}

static notificationIdValidator(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexNotificationId: RegExp = /^(?:[a-zA-Z0-9-]+)?$/;
  const regexStartsWhitespace: RegExp = /^\s/;
   if (control.value != '' && control.value != null) {
     if (regexStartsWhitespace.test(control.value)) {
       return {
         emptyString: true
       };
     } else if (!regexNotificationId.test(control.value)) {
       return {
        invalidNotificationId: true
       };
     }
     if (control.value.length > 36) {
       return {
         maxlength: true
       }
     }
   }
}

static notificationSearchKeyOnlyStringNoSpace(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexSearchKeyWithNoSpaceString: RegExp = /^(?:[a-zA-Z]+)?$/;
  const regexStartsWhitespace: RegExp = /^\s/;
   if (control.value != '' && control.value != null) {
     if (regexStartsWhitespace.test(control.value)) {
       return {
         emptyString: true
       };
     } 
     else if (!regexSearchKeyWithNoSpaceString.test(control.value)) {
      return {
        invalidSearchKeyForString: true
      };
    }
   }
}

static notificationSearchKeyOnlyVarcharNoSpace(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexSearchKeyWithNoSpaceVarchar: RegExp = /^(?:[a-zA-Z0-9]+)?$/;
  const regexStartsWhitespace: RegExp = /^\s/;
   if (control.value != '' && control.value != null) {
     if (regexStartsWhitespace.test(control.value)) {
       return {
         emptyString: true
       };
     }
     else if (!regexSearchKeyWithNoSpaceVarchar.test(control.value)) {
      return {
        invalidSearchKeyForVarchar: true
      };
    }
   }
}

static validateOnlyVarcharNoSpaceWithMaxLength(allowedLength?: number): ValidatorFn {
 
  return (control: AbstractControl) => {
    const regexSearchKeyWithNoSpaceVarchar: RegExp = /^(?:[a-zA-Z0-9]+)?$/;
    const regexStartsWhitespace: RegExp = /^\s/;
    if (control.value != '' && control.value != null) {
      if (regexStartsWhitespace.test(control.value)) {
        return {
          emptyString: true
        };
      }
      else if (!regexSearchKeyWithNoSpaceVarchar.test(control.value)) {
        return {
          invalidSearchKeyForVarchar: true
        };
      }
    }
    if (control.value.length > allowedLength) {
      return {
        maxlength: true
      }
    }
  }
}

static notificationSearchKeyValidNamePattern(control: UntypedFormControl): {
  [key: string]: boolean
} {  
  const regexStartsWhitespace: RegExp = /^\s/;
  const regexPerson: RegExp = /^(?:[a-zA-Z0-9\s,.&'-;:(){}[\]]+)?$/;
  if (control.value != '' && control.value != null) {

    if (regexStartsWhitespace.test(control.value)) {
      return {
        emptyString: true
      };
    } else if (!regexPerson.test(control.value)) {
      return {
        invalidNamePattern: true
      };
    }
    if (control.value.length > 40) {
      return {
        maxlength: true
      }
    }
  }
}

/**
 * End
 */

}//End


